<template>
    <v-app>
        <v-card flat width="400" class="mx-auto mt-5">
            <v-card-title class="pb-0">
                <h1 class="my-16">שחזור סיסמה</h1>
            </v-card-title>
            <v-card-text>
                <v-form @submit.prevent="recoverPassword">
                    <v-text-field 
                        v-model="email" 
                        :rules="emailRules" 
                        prepend-icon="mdi-account" 
                        type="email" 
                        filled 
                        rounded 
                        dense 
                        single-line
                    >
                        <template #label>
                            <span class="red--text"><strong>* </strong></span>דואר
                        </template>
                    </v-text-field>
                    <v-btn type="submit" color="secondary" class="mr-11 px-13" elevation="0">שחזר סיסמה</v-btn>
                </v-form>
            </v-card-text>
        </v-card>
        <v-card flat width="400" class="mx-auto mt-5"></v-card>
    </v-app>
</template>
<script>
import fire from "@/main";

export default {
    data() {
        return {
            email: '',
            emailRules: [
                v => !!v || 'לא יכול להיות ריק',
                v => /.+@.+\..+/.test(v) || 'כתובת דואר לא תקינה'
            ],
        };
    },
    methods: {
        async recoverPassword() {
            try {
                await fire.auth().sendPasswordResetEmail(this.email);
                alert('מייל לשחזור סיסמה נשלח לכתובת הדואר שהזנת. נא לבדוק את תיבת הדואר הנכנס.');
                //go to login page
                this.$router.push('/login');
            } catch (error) {
                console.error(error);
                alert('אירעה שגיאה בעת שחזור הסיסמה. נא לנסות שוב מאוחר יותר או להזין כתובת דואר חוקית.');
            }
        },
    },
};
</script>
<style scoped>
/* Add your component styles here */
</style>
